import { MenuEntry } from '@icebrk-libs/uikit'
import { ICEBRK, ICEDOGE, XICEBRK } from '../../constants'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://icebreak-r.com/',
  },
  {
  label: 'Swap and Liquidity',
  icon: 'TradeIcon',
  items: [
      {
        label: 'Swap ICEBRK',
        href: '/swap/?inputCurrency=BNB&outputCurrency='.concat(ICEBRK.address),
      },
      {
        label: 'ICEBRK Liquidity',
        href: '/add/BNB/'.concat(ICEBRK.address),
      },
      {
        label: 'Swap ICEDOGE',
        href: '/swap/?inputCurrency=BNB&outputCurrency='.concat(ICEDOGE.address),
      },
      {
        label: 'Swap XICEBRK',
        href: '/swap/?inputCurrency=BNB&outputCurrency='.concat(XICEBRK.address),
      },
    ],
  },
  {
    label: 'Stake Tokens',
    icon: 'FarmIcon',
    href: 'https://icebreak-r.com/icebreak-r-network-pools/',
  },
  {
    label: 'Reflection Magnifier',
    icon: 'IfoIcon',
    href: 'https://icebreak-r.com/stake/',
  },
  {
    label: 'Prediction Markets',
    icon: 'PredictionsIcon',
    items: [
      {
        label: "BNB Prediction (Wagering BNB)",
        href: 'https://prediction.icebreak-r.com/#/bnb',
      },
      {
        label: "BNB Prediction (Wagering Icebrk)",
        href: 'https://prediction.icebreak-r.com/#/icebrk',
      },
      {
        label: "BTC Prediction (Wagering BNB)",
        href: 'https://prediction.icebreak-r.com/#/btc',
      },
    ],
  },
  
  {
    label: 'Token Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'IceBreak-R Chart on DexTools',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/'.concat(ICEBRK.address),
      },
      {
        label: 'IceBreak-R on PancakeSwap',
        href: 'https://pancakeswap.info/token/'.concat(ICEBRK.address),
      },
      {
        label: 'IceDoge Chart on DexTools',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/'.concat(ICEDOGE.address),
      },
      {
        label: 'IceDoge on PancakeSwap',
        href: 'https://pancakeswap.info/token/'.concat(ICEDOGE.address),
      },
      {
        label: 'xICEBRK Chart on DexTools',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/'.concat(XICEBRK.address),
      },
      {
        label: 'xICEBRK on PancakeSwap',
        href: 'https://pancakeswap.info/token/'.concat(XICEBRK.address),
      },
    ],
  },
]

export default config
