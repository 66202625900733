import { useEffect, useState } from 'react'
import { ICEBRK, ICEDOGE, BUSD, WBNB } from '../constants'
import { useTradeExactIn } from './Trades'
import { useToken } from './Tokens'
import { tryParseAmount } from '../state/swap/hooks'

type ApiResponse = {
  updated_at: string
  data: {
    [key: string]: {
      name: string
      symbol: string
      price: string
      price_BNB: string
    }
  }
}

const api = 'https://api.pancakeswap.info/api/v2/tokens/'

export const useGetPriceData = () => {
  const iceBrk = useToken(ICEBRK.address)
  const busd = useToken(BUSD.address)
  const parsedAmt = tryParseAmount("10000000000", iceBrk ?? undefined)
  // console.log(parsedAmt)
  const trade = useTradeExactIn(parsedAmt, busd ?? undefined)
  // console.log(trade)
  const priceIce = trade ? trade.outputAmount.numerator[1] / trade.outputAmount.denominator[1] : 0
  // console.log(priceIce)

  return { priceIce }
}

export const useGetIceDogePriceData = () => {
  const icedoge = useToken(ICEDOGE.address)
  const wbnb = useToken(WBNB.address)
  const busd = useToken(BUSD.address)

  const parsedDogeAmt = tryParseAmount("100000", icedoge ?? undefined)
  const dogeToBnbTrade = useTradeExactIn(parsedDogeAmt, wbnb ?? undefined)
  const priceIceDogeBnb = dogeToBnbTrade ? dogeToBnbTrade.outputAmount.numerator[1] / dogeToBnbTrade.outputAmount.denominator[1] : 0
  
  const parsedBnbAmt = tryParseAmount("1", busd ?? undefined)
  const bnbToUsdTrade = useTradeExactIn(parsedBnbAmt, wbnb ?? undefined)
  const priceBusdToBnb = bnbToUsdTrade ? bnbToUsdTrade.outputAmount.numerator[1] / bnbToUsdTrade.outputAmount.denominator[1] : 0
  const bnbToUsd = 1 / priceBusdToBnb

/*   console.log(priceIceDogeBnb.toString())
  console.log(parsedBnbAmt)
  console.log(bnbToUsdTrade)
  console.log(bnbToUsd)
  console.log("\n\n\n\n\n\n\n") */

  const priceIceDoge = priceIceDogeBnb * bnbToUsd
  return { priceIceDoge }
}

export default useGetPriceData